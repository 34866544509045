<template>
  <div class="fqdn-record-table">
    <Loading :data="records">
      <div>
        <FilterInput v-model="filter"></FilterInput>
        <Paginator :current-page="current_page" :per-page="per_page" @current-page="current_page = $event"
                   @per-page="per_page = $event" :total-rows="filtered_and_sorted_records.length"
                   v-if="filtered_and_sorted_records.length > 0"/>
        <b-table responsive :filter="filter" class="shadow" style="min-height: 400px" :items="fs_sliced_records"
                 :fields="fqdn_list_fields"
                 @filtered="onFiltered">
          <template v-slot:cell(info)="data">
            <RecordInfo :item="data.item"></RecordInfo>
          </template>
          <template v-slot:cell(fqdn_description)="data">
            <span style="max-width: 250px; display: inline-block; overflow-wrap: break-word">{{
                data.item.fqdn_description
              }}</span>
          </template>
          <template v-slot:cell(fqdn)="data">
            <b-link :to="`/dnsvs/fqdns/${data.item.fqdn}`">{{ data.item.fqdn }}</b-link>
            <b-badge variant="secondary" v-if="data.item.fqdn_type === 'domain'">{{ $tc('system.domain', 1) }}</b-badge>
            <b-badge v-if="data.item.host_is_nws" variant="primary"
                     :href="$store.state.sysinfo.netdb_admin_base + '/~netadmin/natvs/user/wrapper.cgi/'"
                     :title="$t('components.fqdn_record_table.host_has_natvs_entry')"
                     target="_blank">{{ $t('system.natvs') }}
            </b-badge>
          </template>
          <template v-slot:cell(data)="data">
            <RRDataView :item="data.item" :subnets="subnets"></RRDataView>
          </template>
          <template v-slot:cell(actions)="data">
            <b-button-group>
              <b-dropdown :id="'button-edit-record-' +  data.item.fqdn + '_' + data.item.type + '_' + data.item.data"
                          @click="editItem(data.item)" split variant="outline-primary">
                <template #button-content>
                  <netvs-icon icon="edit"></netvs-icon>
                </template>
                <b-dropdown-item @click="editFQDN(data.item.fqdn)">{{ $t('components.fqdn_record_table.edit_fqdn') }}
                </b-dropdown-item>
              </b-dropdown>
              <RecordDeleteButton :record="data.item"/>
              <b-tooltip :target="'button-edit-record-' +  data.item.fqdn + '_' + data.item.type + '_' + data.item.data"
                         triggers="hover"
                         variant="primary" placement="left">
                {{ $t('components.fqdn_record_table.edit_record') }}
              </b-tooltip>
            </b-button-group>
          </template>
          <template v-slot:cell(ttl)="data">
            <template v-if="data.item.ttl != null">{{ data.item.ttl }}{{ $t('system.second_time_s') }}</template>
            <span v-else :title="$t('components.fqdn_record_table.inherited_from_zone')"
                  class="text-muted">{{ data.item.ttl_zone_default }}{{ $t('system.second_time_s') }}</span>
            <span v-if="data.item.ttl_reset_days"
                  class="text-danger"><br/>{{
                $tc('components.fqdn_record_table.reset_in_days', data.item.ttl_reset_days, { days: data.item.ttl_reset_days })
              }}</span>
            <span v-if="data.item.ttl_reset_date"
                  class="text-danger"><br/>{{
                $tc('components.fqdn_record_table.reset_on_day', 1, { day: data.item.ttl_reset_date })
              }}</span>
          </template>
          <template v-slot:cell(bcds)="data">
            <ul>
              <li v-for="b in get_bcd_for_record(data.item)" :key="b">
                <b-link :to="'/dnsvs/bcds/' + b">{{ b }}</b-link>
              </li>
            </ul>
          </template>
          <template v-slot:head(actions)>
            <b-btn-group class="d-flex" role="group">
              <b-button variant="outline-success" v-if="!fixed_record_types"
                        :id="'button-create-record'" @click="createItem()">
                <netvs-icon icon="create"></netvs-icon>
              </b-button>
              <b-dropdown v-else variant="outline-success" :id="'button-create-record'">
                <template v-slot:button-content>
                  <netvs-icon icon="create"></netvs-icon>
                </template>
                <b-dropdown-item v-for="t in fixed_record_types" :value="t" :key="t" @click="createItem(t)">{{ t }}
                </b-dropdown-item>
              </b-dropdown>
              <b-button variant="outline-primary"
                        :id="'button-export-record'" @click="exportCSV()">
                <netvs-icon icon="export"></netvs-icon>
              </b-button>
            </b-btn-group>
            <b-tooltip :target="'button-create-record'" triggers="hover"
                       variant="success" placement="left">
              {{ $t('components.fqdn_record_table.create_new_record') }}
            </b-tooltip>
            <b-tooltip :target="'button-export-record'" triggers="hover"
                       variant="primary" placement="bottom">
              {{ $t('components.fqdn_record_table.export_record_csv') }}
            </b-tooltip>
          </template>
        </b-table>
        <Paginator :current-page="current_page" :per-page="per_page" @current-page="current_page = $event"
                   @per-page="per_page = $event" :total-rows="filtered_and_sorted_records.length"
                   v-if="filtered_and_sorted_records.length > 0"/>

      </div>
      <CreateDNSRecord modal_id="create_record" :fixed_fqdn="fixed_fqdn"
                       :fixed_record_type="fixed_record_types?type_select:(ip?(ip.includes('.')?'A':'AAAA'):null)"
                       :fixed_data="ip"/>
      <DBEditor :presets="db_editor_presets" :input_reducer="create_record_reducer" modal_id="edit_record"
                :object_title="object_title"
                :object_function="db_editor_function" object_fq_name="dns.record" :old_data="db_editor_old_data"
                :non_optionals_order="['fqdn', 'type', 'data', 'fqdn_description', 'target_is_singleton', 'target_is_reverse_unique']"
      ></DBEditor>
      <DBEditor :presets="db_editor_presets" modal_id="edit_fqdn"
                :object_title="object_title" :input_reducer="full_edit_fqdn_reducer"
                object_function="update" object_fq_name="dns.fqdn" :old_data="db_editor_old_data"
                :non_optionals_order="['value', 'description']"
                :nullable_non_expert="['description']">
      </DBEditor>
    </Loading>
  </div>
</template>

<script>
import DBEditor from './db-editor/APIObjectDBEditor.vue'
import RecordService from '@/api-services.gen/dns.record'
import Loading from './Loading'
import transactionutil from '../util/transactionutil'
import FilterInput from '@/components/FilterInput'
import RRDataView from '@/components/RRDataView'
import RecordInfo from '@/components/RecordInfo'
import CreateDNSRecord from '@/db-editors/CreateDNSRecord.vue'
import RecordDeleteButton from '@/components/RecordDeleteButton.vue'
import apiutil from '@/util/apiutil'
import Paginator from '@/components/Paginator.vue'
import ipaddress from '@/util/ipaddress'

export default {
  name: 'FlatRecordTable',
  components: {
    RecordDeleteButton,
    CreateDNSRecord,
    RecordInfo,
    RRDataView,
    FilterInput,
    Loading,
    DBEditor,
    Paginator
  },
  props: {
    full_edit_fqdn_reducer: Object,
    create_record_reducer: Object,
    records: {
      type: Array,
      required: true
    },
    fqdns: {
      type: Object,
      required: true,
      default() {
        return null
      }
    },
    ip: {
      required: false,
      default() {
        return null
      }
    },
    fixed_fqdn: {
      type: String,
      required: false,
      default() {
        return null
      }
    },
    fixed_record_types: {
      type: Array,
      required: false,
      default() {
        return null
      }
    },
    subnets: {
      required: true,
      type: Array
    }
  },
  computed: {
    filtered_records() {
      if (!this.records) {
        return []
      }
      return this.records.filter(x => this.filterFunc(x, this.filter))
    },
    filtered_and_sorted_records() {
      let recs = [...this.filtered_records]
      if (this.filter) {
        recs = recs.filter(x => this.filterFunc(x, this.filter))
      }
      recs = recs.sort((a, b) => {
        if (this.record_type === 'A' && this.sort_by === 'data') {
          return ipaddress.ip_to_int(a.data) - ipaddress.ip_to_int(b.data)
        } else if (this.record_type === 'AAAA' && this.sort_by === 'data') {
          return ipaddress.compare_ipv6(a.data, b.data)
        } else if (this.sort_by === 'ttl') {
          return (a.ttl || a.ttl_zone_default) - (b.ttl || b.ttl_zone_default)
        } else {
          return (a[this.sort_by] || '').localeCompare((b[this.sort_by] || ''))
        }
      })
      if (this.sort_dir === 'descending') {
        return recs.reverse()
      }
      return recs
    },
    fs_sliced_records() {
      if (this.per_page <= 0) return this.filtered_and_sorted_records
      return this.filtered_and_sorted_records.slice(this.per_page * (this.current_page - 1), this.per_page * this.current_page)
    },
    fqdn_list_fields() {
      let fields = [
        {
          label: this.$t('system.fqdn'),
          key: 'fqdn',
          sortable: true
        },
        {
          label: this.$t('system.more_information'),
          key: 'info',
          sortable: false
        },
        {
          label: this.$t('system.fqdn_description'),
          key: 'fqdn_description',
          sortable: true
        },
        {
          label: this.$t('system.record_type'),
          key: 'type',
          sortable: true
        },
        {
          label: this.$t('system.record_data'),
          key: 'data',
          sortable: true
        },
        {
          label: this.$t('system.ttl'),
          key: 'ttl',
          sortable: true
        }
      ]
      if (this.subnets && this.subnets.length > 0) {
        fields = fields.concat([
          {
            label: this.$tc('system.bcd', 2),
            key: 'bcds',
            sortable: false
          }
        ])
      }
      fields = fields.concat([
        {
          label: this.$tc('system.action', 2),
          key: 'actions',
          sortable: false
        }
      ])
      return fields
    },
    subnets_by_cidr() {
      return apiutil.dict_by_value_of_array(this.subnets, 'cidr')
    }
  },
  data() {
    return {
      filter: '',
      filtered_items: null,
      object_title: null,
      db_editor_function: 'create',
      db_editor_presets: { fqdn: this.fqdn },
      db_editor_old_data: {},
      type_select: null,
      current_page: 1,
      per_page: 50,
    }
  },
  methods: {
    deleteItem: function (item, force_del_ref_records) {
      const itc = {}
      Object.assign(itc, item)
      itc.force_del_ref_records = force_del_ref_records
      const ta = transactionutil.generateDeleteElement('dns.record', RecordService.deleteParamsList(), itc, item.type + '-' + this.$t('components.fqdn_record_table.records_to_fqdn') + ' ' + item.fqdn +
        (force_del_ref_records ? this.$t('components.fqdn_record_table.fqdn_delete_dns_records') : ''))
      this.$store.commit('addTransactionElement', ta)
      this.$emit('commited', ta)
      if (!this.isMobile() && !this.$store.state.show_sidebar_right) {
        this.$store.commit('showSidebarRight', true)
      }
    },
    editItem: function (item) {
      this.db_editor_function = 'update'
      this.db_editor_old_data = item
      this.db_editor_presets = item
      this.object_title = item.type + '-' + this.$t('components.fqdn_record_table.records_to_fqdn') + ' ' + item.fqdn
      this.$root.$emit('bv::show::modal', 'edit_record')
    },
    createItem: function (t = null) {
      if (t) {
        this.type_select = t
      }
      this.$root.$emit('bv::show::modal', 'create_record')
    },
    editFQDN: function (item) {
      this.db_editor_function = 'update'
      this.db_editor_old_data = this.fqdns[item]
      this.db_editor_presets = this.fqdns[item]
      this.object_title = this.$t('system.fqdn') + ' ' + item
      this.$root.$emit('bv::show::modal', 'edit_fqdn')
    },
    onFiltered(filteredItems) {
      this.filtered_items = filteredItems
    },
    exportCSV: function () {
      if (this.filtered_items == null) {
        // If the method is called and there is no filtered list is available, default to the complete list
        this.filtered_items = this.records
      }

      let content = '"FQDN","FQDN_Description","Type","Data","TTL"\n'
      this.filtered_items.forEach((item) => {
        content += `"${item.fqdn ? item.fqdn : ''}",`
        content += `"${item.fqdn_description ? item.fqdn_description : ''}",`
        content += `"${item.type ? item.type : ''}",`
        content += `"${item.data ? item.data : ''}",`
        content += `"${item.ttl != null ? item.ttl : ''}"`
        content += '\n'
      })

      const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' })

      const link = document.createElement('a')
      link.download = 'dns-export-' + this.$route.name + this.fixed_fqdn ? this.fixed_fqdn : '' + this.ip ? this.ip : '' + '.csv'
      link.href = URL.createObjectURL(blob)
      link.click()
      URL.revokeObjectURL(link.href)
    },
    get_bcd_for_record(record) {
      if (!record.rr_chain_target_subnet_gfk_list) {
        return new Set()
      }
      return new Set(this.subnets.filter(net => record.rr_chain_target_subnet_gfk_list.includes(net.gpk)).map(net => net.bcd).filter(net => net.length > 0))
    },
    filterFunc(item, term) {
      if (typeof term === 'string') {
        term = term.toLowerCase()
      }
      for (const v of Object.values(item)) {
        if (typeof v === 'string') {
          if (v.toLowerCase().includes(term)) {
            return true
          }
        }
      }
      return false
    },
  }
}
</script>

<style scoped>

</style>
