<template>
  <div id="null_recs">
    <b-alert variant="info" show>
      <netvs-icon icon="info"/>
      {{$t('components.external_records.info')}}
    </b-alert>
    <b-checkbox :value="true" :unchecked-value="null" v-model="own" switch>
      {{ $t('components.dnsvs_entry.show_only_own') }}
    </b-checkbox>
    <FlatRecordTable :fixed_record_types="record_types" :records="records"
                     :subnets="[]" :fqdns="fqdns"/>
  </div>
</template>

<script>
import FlatRecordTable from '@/components/FlatRecordTable.vue'
import DnsNullRecordsService from '@/api-services/dns_null_records.service'
import TypeService from '@/api-services/dnscfg_types.service'
import apiutil from '@/util/apiutil'

export default {
  name: 'NullRecords',
  components: { FlatRecordTable },
  data() {
    return {
      records: null,
      own: true,
      record_types: null,
      fqdns: null
    }
  },
  async created() {
    await this.fetch_data()
  },
  watch: {
    own: {
      immediate: true,
      async handler() {
        await this.fetch_data()
      }
    }
  },
  methods: {
    async fetch_data() {
      this.records = null
      const record_res = (await DnsNullRecordsService.get_all(this.$store.state, this.own)).data
      this.records = record_res.record_list
      this.fqdns = apiutil.dict_by_value_of_array(record_res.fqdn_list, 'value')
      const r = (await TypeService.getTypes(this.$store.state)).data.non_bcd_types
      const res = []
      for (let i = 0; i < r.length; i++) {
        if (r[i].is_addr_based) {
          continue
        }
        res.push(r[i].name)
      }
      this.record_types = res
    }
  },
}
</script>

<style scoped>

</style>
