<template>
    <div id="FQDNOverview">
        <fqdn-table :fqdns="fqdns"></fqdn-table>
    </div>
</template>

<script>
import FQDNService from '@/api-services/dns_fqdn.service'
import FQDNTable from '@/components/FQDNTable.vue'
import apiutil from '@/util/apiutil'

export default {
  name: 'FQDNOverview',
  data () {
    return {
      fqdns: null
    }
  },
  created () {
    FQDNService.getOwnFQDNs(this.$store.state).then((response) => {
      this.fqdns = apiutil.uniq_by(response.data.ou_fqdns.concat(response.data.group_fqdns))
      Object.freeze(this.fqdns)
    })
  },
  components: {
    'fqdn-table': FQDNTable
  }
}
</script>
