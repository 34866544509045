<template>
  <div id="dnsvs_Entry">
    <h1>{{ $t('system.dnsvs') }}</h1>
    <div v-if="$store.state.expert">
      <b-row>
        <b-col>
          <b-button :disabled="!create_record_reducer" variant="outline-success" block
                    @click="$bvModal.show('create_record_entry')">
            <netvs-icon icon="create"/>
            {{ $t('components.dnsvs_entry.create_record') }} <NetvsExpertMarker/>
          </b-button>
        </b-col>
        <b-col>
          <b-button :disabled="!create_fqdn_reducer" variant="outline-success" block
                    @click="$bvModal.show('create_fqdn_entry')">
            <netvs-icon icon="create"/>
            {{ $t('components.dnsvs_entry.create_fqdn') }} <NetvsExpertMarker/>
          </b-button>
        </b-col>
      </b-row>
      <hr>
    </div>
    <b-nav tabs align="center">
      <b-nav-item :active="$route.name === 'bcds'" to="/dnsvs/bcds">{{
          $t('components.dnsvs_entry.your_bcds')
        }}
      </b-nav-item>
      <b-nav-item :active="$route.name === 'fqdns'" to="/dnsvs/fqdns">{{
          $t('components.dnsvs_entry.your_domains')
        }}
      </b-nav-item>
      <b-nav-item :active="$route.name === 'null_recs'" to="/dnsvs/null_records">
        {{
          $t('components.dnsvs_entry.null_records')
        }}
      </b-nav-item>
    </b-nav>
    <BCDOverview v-if="$route.name === 'bcds'"></BCDOverview>
    <FQDNOverview v-if="$route.name === 'fqdns'"></FQDNOverview>
    <ExternalRecords v-if="$route.name === 'null_recs'"></ExternalRecords>
    <DNSRecord object_function="create" modal_id="create_record_entry"/>
    <DBEditor :input_reducer="create_fqdn_reducer" modal_id="create_fqdn_entry"
              object_function="create" object_fq_name="dns.fqdn"
              :non_optionals_order="['value', 'type', 'description']"
              :nullable_non_expert="['description']">
    </DBEditor>
  </div>
</template>

<script>
import BCDOverview from '@/views/dnsvs/BCDOverview.vue'
import FQDNOverview from '@/views/dnsvs/FQDNOverview.vue'
import ExternalRecords from '@/views/dnsvs/ExternalRecords.vue'
import DBEditor from '@/components/db-editor/APIObjectDBEditor.vue'
import DNSTypeService from '@/api-services/dns_type.service'
import NetvsExpertMarker from '@/components/NETVSExpertMarker.vue'
import apiutil from '@/util/apiutil'
import DNSRecord from '@/db-editors/CreateDNSRecord.vue'

export default {
  name: 'DNSVSEntry',
  components: { DNSRecord, NetvsExpertMarker, BCDOverview, FQDNOverview, DBEditor, ExternalRecords },
  data() {
    return {
      create_record_reducer: undefined,
      create_fqdn_reducer: undefined
    }
  },
  async created() {
    const typesReq = await DNSTypeService.getTypes(this.$store.state)
    const fqdnSelections = {}
    for (const t of typesReq.data.fqdn_type_list) {
      let disp_name = t.description
      disp_name += ' ['
      disp_name += t.is_nonterminal ? this.$t('views.dnsvs.dnsvs_entry.non-terminal') : this.$t('views.dnsvs.dnsvs_entry.terminal')
      disp_name += ']'
      fqdnSelections[t.name] = { display_name: disp_name }
    }
    this.create_fqdn_reducer = {
      type: fqdnSelections
    }
    const selections = {}
    for (const t of typesReq.data.record_inttype_list) {
      selections[t.record_type] = { display_name: t.record_type }
    }
    const record_fqdn_type_reducer = Object.assign({ '': { display_name: this.$t('views.dnsvs.dnsvs_entry.automatic_handling') } }, this.create_fqdn_reducer.type)
    this.create_record_reducer = {}
    this.create_record_reducer = {
      type: selections,
      fqdn_type: record_fqdn_type_reducer
    }
  },
  methods: {
    checkRights: apiutil.checkPermission
  }
}
</script>

<style scoped>

</style>
