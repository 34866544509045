import TransactionService from './transaction.service'

export default {
  get_all(config, own) {
    const ta = [
      { name: 'dns.record.list', old: { is_own: own, sorting_params_list: ['type', 'fqdn'], rr_chain_target_subnet_gfk_list: null, is_auth: true } },
      { name: 'dns.fqdn.list', inner_join_ref: { record_list: 'default' } },
    ]
    if (own === null) {
      delete ta[0].old.is_own
    }
    return TransactionService.execute(config, ta)
  }
}
