import TransactionService from './transaction.service'

export default {
  getTypes(config) {
    const ta = [
      { name: 'dnscfg.fqdn_type.list', old: { sorting_params_list: ['position'] } },
      { name: 'dnscfg.record_inttype.list', old: { is_own: true, sorting_params_list: ['record_type'] } }
    ]
    return TransactionService.execute(config, ta)
  }
}
